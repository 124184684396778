<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Create Email Setting</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>


        <div id="error" class="card border-danger" v-show="error">
          <div class="card-header bg-danger border-danger">

              <span class="text-white font-weight-bolder">
              <i class="fa fa-exclamation-triangle"></i>
               &nbsp;
              {{ error_heading }}
              </span>

          </div>
          <div class="card-body border-danger text-dark font-weight-bold">

            {{ error_text }}

          </div>
        </div>

        <div>

          <b-form-row class="mb-3">

            <b-col cols="5">
              <label class="">From Email</label>
              <b-input v-model="$v.details.from_email.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateState('from_email')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.from_email.required">This is a required field.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.details.from_email.email">Please enter a valid email address.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="5">
              <label class="">CC Email</label>
              <b-input v-model="$v.details.cc_email.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('cc_email')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.cc_email.required">This is a required field.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.details.cc_email.email">Please enter a valid email address.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="2">
              <label class="">Product</label>
              <b-form-select v-model="$v.details.product.$model" :options="options.products" :state="validateState('product')"></b-form-select>
              <b-form-invalid-feedback v-if="!$v.details.product.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

          </b-form-row>


          <b-form-row v-if="details.emails.length > 0" v-for="(item, index) in details.emails" :key="index" class=" my-3">

            <input type="hidden" v-model="item.id">

            <b-col cols="4">

              <label class="">Name</label>
              <b-input type="text" v-model="item.name" :state="validateEmailItemState(index, 'name')"></b-input>
              <b-form-invalid-feedback v-if="!item.name.required">This is a required field.</b-form-invalid-feedback>

            </b-col>

            <b-col cols="4">

              <label class="">Email Address</label>
              <b-input type="email" v-model="item.email" :state="validateEmailItemState(index, 'email')"></b-input>
              <b-form-invalid-feedback v-if="!item.email.required">This is a required field.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!item.email.email">Please enter a valid email address.</b-form-invalid-feedback>

            </b-col>

            <b-col cols="3">

              <label class="">CC Email?</label>
              <b-form-select v-model="item.cc" :options="options.yes_no" :state="validateEmailItemState(index, 'cc')"></b-form-select>
              <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

            </b-col>

            <b-col cols="1" class="text-center">

              <label class="">&nbsp;</label> <br>

              <b-button variant="outline-danger" class="mt-2" @click="deleteEmailItem(index)">
                <i class="fa fa-times"></i>
              </b-button>

            </b-col>

          </b-form-row>

          <b-button variant="outline-success" class="" @click="addEmailItem">
            <i class="fa fa-plus"></i> Add Email
          </b-button>

        </div>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button variant="success" @click="createEmailSetting" class="float-right">Create Email Setting</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import { validationMixin } from 'vuelidate'
  import {email, required} from "vuelidate/lib/validators";

  export default {
    name: 'EmailSettingCreate',
    components: {
      Id,
    },
    mixin: [validationMixin],
    props: {
      caption: {
        type: String,
        default: 'Create'
      },
    },
    data: () => {
      return {
        details: {
         from_email: "",
         cc_email: "",
         product: "",
         products: [],
         emails: []
        },
        options: {
          products: [],
          yes_no: [
            {
              value: "",
              text: 'Please select'
            },
            {
              value: true,
              text: 'Yes'
            },
            {
              value: false,
              text: 'No'
            }
          ]
        },
        error: false,
        error_heading: '',
        error_text: '',
        server_errors: {
          from_email: null,
          cc_email: null,
          product: null,
          products: null,
          emails: null
        },
      }
    },
    validations() {

      return {
        details: {
          from_email: {
            required
          },
          cc_email: {
            required
          },
          product: {
            required
          },
          emails: {
            $each: {
              name: {
                required
              },
              email: {
                email,
                required
              },
              cc: {
                required
              }
            }
          },
        }
      }

    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      validateState(name) {

        if (this.$v.details[name]) {
          const {$dirty, $error} = this.$v.details[name];
          return $dirty ? !$error : null;
        }
      },
      resetServerError(name) {

        if (this.server_errors[name]) {
          this.server_errors[name] = null;
        }

      },
      getProducts() {
        axios.get('/email-settings/products/').then(
          response => {
            this.options.products = response.data;
          }
        ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
      },
      validateEmailItemState(index, name) {
        if (this.$v.details.emails) {
          if (this.$v.details.emails.$each[index]) {
            if (this.$v.details.emails.$each[index][name]) {
              const {$dirty, $error} = this.$v.details.emails.$each[index][name];
              return $dirty ? !$error : null;
            }
          }
        }
      },
      addEmailItem() {
        const email = {
          id: null,
          name: "",
          email: "",
          cc: ""
        };
        this.details.emails.push(email);
        this.$options.validations()
      },
      deleteEmailItem(id) {
        this.$delete(this.details.emails, id)
      },
      createEmailSetting() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          const post_data = JSON.parse(JSON.stringify(this.details));

          axios.post('/email-settings/', post_data).then(response => {
            response
            let alert = {
              type: 'success',
              text: 'Email Settings : for ' + this.product + ' was created successfully!'
            };

            this.$store.commit(
              'setGlobalAlert',
              alert
            )
            this.$router.replace({name: 'SCEmailSettings'})

        }).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            if (error.response.status === 400) {

              window.scrollTo(document.querySelector('#error').offsetTop, 100);
              this.error = true;
              this.error_heading = 'There are errors on the form';
              this.error_text = 'Please fix the errors and re-submit the form';

              if (error.response.data) {

                //this.server_errors = error.response.data

                for (var key in error.response.data){

                  this.server_errors[key] = error.response.data[key][0]
                }
                this.$v.$reset();
                this.$v.$touch();

              }

            }

            console.log('email settings error');
            console.log(error.response)
          });

        }
      }
    },
    mounted() {
      this.getProducts()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
